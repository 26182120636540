export default class Marquee {
  constructor () {
    jQuery('.smooth-logo-slider').each(function () {
      var block = jQuery(this).find('.line')
      var cloneBlock = block.find('.logo-slide')
      var multiply = Math.ceil(2100 / cloneBlock.width())

      for (var x = 1; x <= multiply; x++) {
        var cloned = cloneBlock.clone()
        block.append(cloned)
      }
    })

    jQuery('.smooth-logo-slider').marquee({
      line: '.line',
      animSpeed: 75,
      mask: '.mask'
    })
  }
}
